import { Box, Typography } from '@mui/material';
import Link from 'components/Link';
import { useUser } from 'context/user.context';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
// import styles from './nav.module.css';

interface NewIndicatorCss {
  backgroundColor: string;
  color: string;
  fontSize: number;
  padding: string;
  borderRadius: string;
  position: 'absolute';
  transform: string;
  bottom: string;
  left: string;
  [key: string]: any;
}

const NavIconsDesktop = () => {
  const [userData] = useUser();
  const role_id = userData.user?.role_id;
  const margin = 3;
  const router = useRouter();

  const Separator = () => <Box flexBasis="1px" bgcolor="#C4C4C4" />;

  const dotCss = useMemo(
    () => ({
      width: 6,
      height: 6,
      bgcolor: '#009860',
      content: "''",
      borderRadius: 10,
      display: 'block',
      left: '50%',
      position: 'absolute',
      transform: 'translate(-50%, 0)',
    }),
    [],
  );

  const newIndicatorCss: NewIndicatorCss = {
    backgroundColor: 'purple', // Customize the background color
    color: 'white', // Customize the text color
    fontSize: 12,
    padding: '2px 6px',
    borderRadius: '5px',
    position: 'absolute',
    transform: 'translate(-50%, -50%)', // Center the indicator
    bottom: '40%',
    left: '100%',
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexGrow={1}
      sx={{
        display: {
          md: 'inherit',
          xs: 'none',
        },
      }}
    >
      {role_id == 2 ? null : (
        <>
          <Link noLinkStyle href="/" sx={{ mr: margin }}>
            <Typography
              sx={{
                position: 'relative',
                ...(router.pathname === '/' && {
                  '&::after': dotCss,
                  fontWeight: 500,
                }),
              }}
            >
              Invest
            </Typography>
          </Link>
          <Separator />
        </>
      )}
      {role_id == 1 ? (
        <Link noLinkStyle href="/startups" sx={{ ml: margin }}>
          <Typography
            sx={{
              position: 'relative',
              ...(router.pathname === '/startups' && {
                '&::after': dotCss,
                fontWeight: 500,
              }),
            }}
          >
            Startups
          </Typography>
        </Link>
      ) : (
        <Link noLinkStyle href="/raise-funds" sx={{ ml: margin }}>
          <Typography
            sx={{
              position: 'relative',
              ...((router.pathname === '/raise-funds' ||
                (role_id == 2 && router.pathname === '/')) && {
                '&::after': dotCss,
                fontWeight: 500,
              }),
            }}
          >
            Raise Fund
          </Typography>
        </Link>
      )}
      {/* <Link noLinkStyle href="/we-pop" sx={{ ml: margin }}>
        <Typography fontWeight={500} className={styles['gradient-text']}>
          We POP
        </Typography>
      </Link> */}

      <Link
        noLinkStyle
        href="https://pitchpager.com"
        target="_blank"
        sx={{ mx: margin }}
      >
        <Typography
          sx={{
            position: 'relative',
            ...(router.pathname === '/create-one-pager' && {
              '&::after': dotCss,
              fontWeight: 500,
            }),
          }}
        >
          PitchPager
          <span style={{ ...newIndicatorCss }}>New</span>
        </Typography>
      </Link>
      <Separator />
      <Link noLinkStyle href="/faqs" sx={{ ml: margin }}>
        <Typography
          sx={{
            position: 'relative',
            ...(router.pathname === '/faqs' && {
              '&::after': dotCss,
              fontWeight: 500,
            }),
          }}
        >
          FAQs
        </Typography>
      </Link>
      <Link
        noLinkStyle
        href="/pod-cafe"
        sx={{
          ml: margin,
        }}
      >
        <Typography
          sx={{
            position: 'relative',
            ...(router.pathname === '/pod-cafe' && {
              '&::after': dotCss,
              fontWeight: 500,
            }),
          }}
        >
          POD Cafe
        </Typography>
      </Link>
      {role_id ? (
        <Link
          noLinkStyle
          href={`/${role_id == 1 ? 'investor' : 'startup'}-how-it-works`}
          sx={{ ml: margin }}
        >
          <Typography
            sx={{
              position: 'relative',
              ...(router.pathname === '/investor-how-it-works' ||
              router.pathname === '/startup-how-it-works'
                ? {
                    '&::after': dotCss,
                    fontWeight: 500,
                  }
                : {}),
            }}
          >
            How It Works
          </Typography>
        </Link>
      ) : null}
      <Link noLinkStyle href="/about" sx={{ ml: margin }}>
        <Typography
          sx={{
            position: 'relative',
            ...(router.pathname === '/about' && {
              '&::after': dotCss,
              fontWeight: 500,
            }),
          }}
        >
          About
        </Typography>
      </Link>
    </Box>
  );
};

export default NavIconsDesktop;
