import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#008B5E',
      light: '#00C659',
    },
    secondary: {
      dark: '#BDFAE6',
      main: '#D9FBF3',
      light: 'rgba(242, 255, 251, 0.98)',
    },
    customBackground: {
      main: '#BAE8E8',
      dark: '#ABD1C6',
      light: '#F3FBFB',
    },
    text: {
      primary: '#272343',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1650,
    },
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 960,
  //     lg: 1080,
  //     xl: 1920,
  //   },
  // },
});

export default theme;

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface Palette {
    customBackground: Palette['primary'];
  }

  interface PaletteOptions {
    customBackground: PaletteOptions['primary'];
  }
}
