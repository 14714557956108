import { Box, Typography } from '@mui/material';
import Link from 'components/Link';
// import styles from './nav.module.css';

interface HamburgerOptionProps {
  icon: any;
  text: string;
  link: string;
  glow?: boolean;
}

export const HamburgerOption = ({
  icon,
  link,
  text,
  glow,
}: HamburgerOptionProps) => {
  return (
    <Link noLinkStyle href={link}>
      <Box px={2.5} component="nav" py={1} display="flex">
        <Box
          width={22}
          height={22}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={icon} alt={text} />
        </Box>
        <Typography
          fontSize={14}
          ml={2}
          component="span"
          fontWeight={500}
          // className={glow ? styles['gradient-text'] : undefined}
        >
          {text}
        </Typography>
      </Box>
    </Link>
  );
};
