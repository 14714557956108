import { List, ListItem, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { Link, useHistory } from 'react-router-dom';
import portfolioIcon from 'assets/images/portfolio-icon.svg';
import favouritesIcon from 'assets/images/favourites-icon.svg';
import supportIcon from 'assets/images/support-icon.svg';
import profileIcon from 'assets/images/profile-icon.svg';
import notificationsIcon from 'assets/images/notifications-icon.svg';
import pepolGroup from 'assets/images/people-group.svg';
import documentSVG from 'assets/images/document.svg';
import startupIcon from 'assets/images/startup_icon.svg';
import { useRouter } from 'next/router';
import Link from 'components/Link';
import { useUser } from 'context/user.context';
// import { setUserDataToStorage as updateUserAuthData } from './CommonApis';

export function DashboardSidebar() {
  const theme = useTheme();
  const useStyles = makeStyles({
    sidebarMenu: {
      background: '#51947E',
      width: 200,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
      },

      '& ul': {
        padding: '45px 20px 0 20px',
        [theme.breakpoints.down('sm')]: {
          padding: '45px 20px 20px 20px',
        },

        '& li': {
          padding: 0,
          display: 'block',
          marginBottom: 10,

          '& a': {
            color: '#fff',
            fontSize: 18,
            lineHeight: 1,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '10px 0 10px 15px',
            borderRadius: 5,

            '& img': {
              marginRight: 10,
              width: 20,
              height: 20,
            },

            '&:hover': {
              background: '#3C8069',
            },

            '&.active': {
              background: '#3C8069',
            },
          },
        },
      },
    },
    logo: {
      display: 'flex',
      flexWrap: 'wrap',
      borderBottom: '1px solid #72BAA2',
      justifyContent: 'center',
      padding: '23px 0',

      '& img': {
        maxWidth: '100%',
        display: 'block',
      },
    },
  });
  const classes = useStyles();
  // const [userDetail, setuserDetail] = useState([]);
  const [userData] = useUser();
  const { user: userDetail, isLoading } = userData;
  const history = useRouter();
  const pathName = history.pathname;

  if (isLoading) {
    return null;
  }

  if (!userDetail) {
    history.replace('/login');
    return null;
  }

  // if (!userDetail) {
  //   history.replace('/login');
  // }
  // useEffect(() => {
  //   updateUserAuthData();
  //   const userData = JSON.parse(localStorage.getItem('userData'));
  //   setuserDetail(userData);
  // }, []);

  return (
    <div className={classes.sidebarMenu}>
      {userDetail.role_id === 2 ? (
        <List>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/investor-summary"
              className={pathName === '/investor-summary' ? 'active' : ''}
            >
              <img src={portfolioIcon} alt="" />
              <span>Investors</span>
            </Link>
          </ListItem>
          {userDetail?.company?.private &&
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/private-invites"
              className={pathName === '/private-invites' ? 'active' : ''}
            >
              <img src={portfolioIcon} alt="" />
              <span>Private Invites</span>
            </Link>
          </ListItem>}
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/startup-profile"
              className={pathName === '/startup-profile' ? 'active' : ''}
            >
              <img src={profileIcon} alt="" />
              <span>Profile</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/startup-notifications"
              className={pathName === '/startup-notifications' ? 'active' : ''}
            >
              <img src={notificationsIcon} alt="" />
              <span>Notifications</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/startup-support"
              className={pathName === '/startup-support' ? 'active' : ''}
            >
              <img src={supportIcon} alt="" />
              <span>Support</span>
            </Link>
          </ListItem>
        </List>
      ) : (
        <List>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/investor-portfolio"
              className={pathName === '/investor-portfolio' ? 'active' : ''}
            >
              <img src={portfolioIcon} alt="" />
              <span>Portfolio</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/favorites"
              className={pathName === '/favorites' ? 'active' : ''}
            >
              <img src={favouritesIcon} alt="" />
              <span>Favorites</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/investor-support"
              className={pathName === '/investor-support' ? 'active' : ''}
            >
              <img src={supportIcon} alt="" />
              <span>Support</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/investor-profile"
              className={pathName === '/investor-profile' ? 'active' : ''}
            >
              <img src={profileIcon} alt="" />
              <span>Profile</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/investor-notifications"
              className={pathName === '/investor-notifications' ? 'active' : ''}
            >
              <img src={notificationsIcon} alt="" />
              <span>Notifications</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/investor-refer-a-friend"
              className={
                pathName === '/investor-refer-a-friend' ? 'active' : ''
              }
            >
              <img src={pepolGroup} alt="" />
              <span>Refer & Earn</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/investor-refer-a-startup"
              className={
                pathName === '/investor-refer-a-startup' ? 'active' : ''
              }
            >
              <img src={startupIcon} alt="" color='white' />
              <span>Refer Startup</span>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              sx={{ textDecoration: 'none' }}
              href="/investment-kyc"
              className={pathName === '/investment-kyc' ? 'active' : ''}
            >
              <img src={documentSVG} alt="" />
              <span>KYC</span>
            </Link>
          </ListItem>
        </List>
      )}
    </div>
  );
}
