import { styled } from '@mui/system';

export const UnderlinedButton = styled('button')<{ white?: boolean }>(
  ({ theme, white }) => ({
    textDecoration: 'none',
    fontFamily: 'inherit',
    fontSize: 16,
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    paddingTop: 7,
    paddingBottom: 7,
    backgroundColor: white ? '#fff' : '#C9EDED',
    border: 'none',
    // borderBottom: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `inset 0px -2px 0px 0px ${
      white ? '#000' : theme.palette.primary.main
    }`,
    color: white ? '#070707' : theme.palette.primary.main,
    fontWeight: 500,
    paddingLeft: 24,
    paddingRight: 24,
    cursor: 'pointer',
  }),
);
