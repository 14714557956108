import {
  createContext,
  FC,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';

type ContextValue = [string | null, Dispatch<SetStateAction<string | null>>];

const RedirectUrlContext = createContext<ContextValue | undefined>(undefined);

export function useRedirectUrl() {
  const context = useContext(RedirectUrlContext);
  if (!context) {
    throw new Error('useRedirectUrl must be used inside RedirectUrlProvider');
  }
  return context;
}

export const RedirectUrlProvider: FC = ({ children }) => {
  const value: ContextValue = useState<string | null>(null);

  return (
    <RedirectUrlContext.Provider value={value}>
      {children}
    </RedirectUrlContext.Provider>
  );
};
