import { Box } from '@mui/material';
import Footer from 'components/Layout/Footer';
import { FC } from 'react';
import { Nav } from './Nav';

const Layout: FC = ({ children }) => {
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Nav />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
