import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import Layout from 'components/v2/Layout';
import { CampaignProvider } from 'context/campaign.context';
import { RedirectUrlProvider } from 'context/redirectUrl.context';
import { UserProvider } from 'context/user.context';
import { GTMPageView, GTM_ID } from 'lib/gtm';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Router } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'styles/globals.css';
import { urlBase64ToUint8Array } from 'utils';
import theme from '../styles/theme';
import createEmotionCache from '../utils/createEmotionCache';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  // Initiate GTM
  useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url);
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    hotjar.initialize(3179586, 6);
  }, []);

  useEffect(() => {
    if ('serviceWorker' in window.navigator) {
      console.log('useEffect');
      navigator.serviceWorker.ready
        .then(async (swreg) => {
          const existingSub = await swreg.pushManager.getSubscription();
          if (existingSub === null) {
            const vapidPublicKey =
              'BJXpY0E19ip3lmUM5nxhvAbP_QGydI0LD9MOvoqjwEkuXJHulMyZLkL-bBlJMx8f0_5ZKZCgVwrigO9sFMQTNTk';
            const convertedVapidPublicKey =
              urlBase64ToUint8Array(vapidPublicKey);

            const newSub = await swreg.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidPublicKey,
            });

            axios
              .post(
                `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/push-notification/subscribe`,
                {
                  subscription: newSub,
                },
              )
              .then((res) => console.log(res.data))
              .catch((err) => console.log(err));
          } else {
            axios
              .post(
                `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/push-notification/subscribe`,
                {
                  subscription: existingSub,
                },
              )
              .then((res) => console.log(res.data))
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/sw.js', { scope: '/' })
        .then(function () {
          console.log('Service worker registered!');
        });
    }

    window.addEventListener('beforeinstallprompt', function (event) {
      console.log('beforeinstallprompt fired');
      event.preventDefault();
      return false;
    });
  }, []);
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  
  return (
    <CacheProvider value={emotionCache}>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=AW-11037445570"
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');`,
        }}
      />
      <Script src="https://sdk.cashfree.com/js/ui/2.0.0/cashfree.prod.js" />
      <Head>
        <title>POD - Invest in startups</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <RedirectUrlProvider>
            <CampaignProvider>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </CampaignProvider>
          </RedirectUrlProvider>
        </UserProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
