import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Link from 'components/Link';
import { useRouter } from 'next/router';
import { ProfileButton } from './ProfileButton';
import { useUser } from 'context/user.context';
import { CustomButton } from 'components/common';

export const NavLink: FC<{
  active: boolean;
  href: string;
  children: string;
  mobile: boolean;
  onClick: () => void;
  disabled?: boolean;
}> = ({ active, href, children, mobile, onClick, disabled }) => (
  <Box mx={mobile ? 0 : 1.5} py={mobile ? 1.5 : 0} position="relative">
    {disabled ? (
      <Typography fontWeight={500} sx={{ cursor: 'default', opacity: 0.6 }}>
        {children}
      </Typography>
    ) : (
      <Link
        onClick={onClick}
        sx={{
          textDecoration: 'none',
          fontWeight: 500,
          color: active ? (theme) => theme.palette.primary.main : '#111111',
          '&:hover': {
            color: (theme) => theme.palette.primary.main,
            '& + div': {
              display: 'block',
            },
          },
          fontSize: 16,
          transition: 'all .5s ease',
        }}
        href={href}
      >
        {children}
      </Link>
    )}

    <Box
      sx={{
        bgcolor: (theme) => theme.palette.primary.main,
        position: 'absolute',
        height: '1px',
        width: '100%',
        display: active ? 'block' : 'none',
      }}
    />
  </Box>
);

export function NavLinks({
  mobile,
  setMobileMenuOpen,
}: {
  mobile: boolean;
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const [userData, logout] = useUser();
  const [_redirectUrl, _setRedirectUrl] = useState<string | null>(null);

  const { user } = userData;

  const roleId: number | undefined = user?.role_id;

  const logoutUser = async () => {
    logout();
    const roleId = user?.role_id;
    roleId === 2 ? router.push('/raise-funds') : router.push('/');
    // setOpenDrawerHandler(false);
  };

  return (
    <>
      {!roleId && (
        <>
          <NavLink
            onClick={() => setMobileMenuOpen(false)}
            mobile={mobile}
            active={router.pathname === '/'}
            href="/"
          >
            Invest
          </NavLink>

          <NavLink
            onClick={() => setMobileMenuOpen(false)}
            mobile={mobile}
            active={router.pathname === '/raise-funds'}
            href="/raise-funds"
          >
            Raise Funds
          </NavLink>
        </>
      )}
      {roleId === 1 && (
        <>
          <NavLink
            onClick={() => setMobileMenuOpen(false)}
            mobile={mobile}
            active={router.pathname === '/'}
            href="/"
          >
            Invest
          </NavLink>
          <NavLink
            onClick={() => setMobileMenuOpen(false)}
            mobile={mobile}
            active={router.pathname === '/startups'}
            href="/startups"
          >
            Startups
          </NavLink>
        </>
      )}
      {roleId === 2 && (
        <>
          <NavLink
            onClick={() => setMobileMenuOpen(false)}
            mobile={mobile}
            active={router.pathname === '/'}
            href="/"
          >
            Raise Funds
          </NavLink>
        </>
      )}

      <NavLink
        onClick={() => setMobileMenuOpen(false)}
        mobile={mobile}
        active={router.pathname === '/faqs'}
        href="/faqs"
      >
        FAQs
      </NavLink>
      <NavLink
        onClick={() => setMobileMenuOpen(false)}
        mobile={mobile}
        active={router.pathname === '/pod-cafe'}
        href="/pod-cafe"
      >
        POD Cafe
      </NavLink>

      {roleId === 2 && (
        <>
          <NavLink
            onClick={() => setMobileMenuOpen(false)}
            mobile={mobile}
            active={router.pathname === '/startup-how-it-works'}
            href="/startup-how-it-works"
          >
            How It Works
          </NavLink>
        </>
      )}
      {roleId === 1 && (
        <>
          <NavLink
            onClick={() => setMobileMenuOpen(false)}
            mobile={mobile}
            active={router.pathname === '/investor-how-it-works'}
            href="/investor-how-it-works"
          >
            How It Works
          </NavLink>
        </>
      )}
      <NavLink
        onClick={() => setMobileMenuOpen(false)}
        mobile={mobile}
        active={router.pathname === '/'}
        href="https://pitchpager.com"
      >
        PitchPager
      </NavLink>
      <NavLink
        onClick={() => setMobileMenuOpen(false)}
        mobile={mobile}
        active={router.pathname === '/about'}
        href="/about"
      >
        About
      </NavLink>
      {!roleId && (
        <>
          <NavLink
            onClick={() => setMobileMenuOpen(false)}
            mobile={mobile}
            active={router.pathname === '/login'}
            href="/login"
          >
            Login
          </NavLink>
          <Link
            onClick={() => setMobileMenuOpen(false)}
            href="/register"
            sx={{
              ml: {
                xs: 0,
                md: 1.5,
              },
            }}
          >
            <CustomButton
              sx={{ fontSize: 14, width: 100, height: 30 }}
              variant="contained"
              disableElevation
            >
              Sign Up
            </CustomButton>
          </Link>
          {/* <NavLink
            mobile={mobile}
            active={router.pathname === '/sign-up'}
            href="/sign-up"
          >
            Sign Up
          </NavLink> */}
        </>
      )}
      {/* {roleId === 1 || roleId === 2 ? (
        <NavLink
          mobile={mobile}
          active={router.pathname === '/Avatar'}
          href="/Avatar"
        >
          Avatar
        </NavLink>
      ) : null} */}
      {user && (
        <ProfileButton
          sx={{
            ml: {
              xs: 0,
              md: 1.5,
            },
          }}
          handleLogout={logoutUser}
          image={user?.profile_image}
          name={
            user?.first_name?.[0]?.toUpperCase() +
              user?.last_name?.[0]?.toUpperCase() || ''
          }
          onClick={() => setMobileMenuOpen(false)}
        />
      )}
    </>
  );
}
